import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "name": "Fake likes",
  "route": "/fake-likes"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Fake likes`}</h1>
    <p>{`An anti-fake tax (5%) is taken from smartlikes to mitigate fake likes - promotions of one's own content to manipulate charts and recommendations.`}</p>
    <p>{`The tax is returned by even distribution among creators on regular payouts. In order to receive tax returns creator accounts need to have an Internet domain, YouTube, Twitter or Telegram account connected to prevent abusers from creating ghost accounts just for the sake of getting tax returns. Tax return rate is daily adjusted to current smartlike volume. The returns are accumulated until the next payout even if a creator has no current payouts.`}</p>
    <p>{`The protection against fake likes cuts more from successful creators and distributes the amount among less fortunate ones, hence the name. Abusers will have to pay to those they play against, while superstars will share part of their likes with less successful creators for the sake of system integrity. We work towards a fairer algorithm that supports with anonymous accounts.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      